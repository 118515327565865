interface Translations {
  [key: string]: {
    [key: string]: string;
  };
}

export const translations: Translations = {
  en: {
    createNewBook: 'Create a new book',
    plot: 'Plot',
    enterPlot: 'Enter the plot for your story',
    expandForMore: 'Expand for more details (optional)',
    characters: 'Characters',
    enterCharacters: 'Who is your story about (separate with \'and\')',
    durationMinutes: 'Duration in Minutes',
    enterDuration: 'How long should your story be?',
    childAge: 'Child Age',
    enterChildAge: 'How old (in years) is the target audience?',
    inspiredByBook: 'Inspired By Book',
    enterInspiredBook: 'Title of book to use as inspiration',
    storytellingModel: 'Storytelling Model',
    storyArtModel: 'Story Art Model',
    createStory: 'Create Story',
    library: 'Library',
    newStory: 'New Story',
    poweredBy: 'Powered by',
    letsRead: 'Let\'s read',
    removeStory: 'Remove Story',
    emptyLibrary: 'Empty Library',
    noStoriesSaved: 'It seems you don\'t have any stories saved.',
    createNewOne: 'That\'s OK - use your imagination to create a new one!',
    removeBookTitle: 'Remove Book',
    removeBookConfirm: 'Are you sure you want to remove this story from your library?',
    removeBookNote: 'People that the story was shared with can still view it.',
    cancel: 'Cancel',
    remove: 'Remove',
    youHave: 'You have ',
    storiesInLibrary: ' stories in your library. Click on one below to get more details and read it again. ',
    story: 'You have a story in your library. Click on it below to get more details and read it again.',
    searchLibrary: 'Search library',
    showing: 'showing',
    createdByMe: 'Created by me',
    readByMe: 'Read by me',
    createAnotherLike: 'Create another story like this one...',
    mustProvidePlot: 'Must provide a plot',
    durationInvalid: 'Duration minutes is an invalid number',
    ageInvalid: 'Child age is an invalid number',
    statusInitializing: 'That\'s a great idea, let me get started',
    statusCreating: 'I\'m thinking...',
    statusTranscribing: 'I\'m recording my voice and drawing some pictures...',
    statusReady: 'All set!',
    statusQueued: 'I\'m busy helping others right now, I\'ll be with you in a few moments. Sit tight and get the hot chocolate ready!',
    pageNumber: 'Page',
    pageOf: 'of',
    waitForSnack: 'This could take a while, go grab a snack and come back in a few minutes',
    transcribedCount: 'Transcribed {0} of {1} recordings',
    illustratedCount: 'Illustrated {0} of {1} images',
    validationPromptCantBeBlank: "Prompt can't be blank",
    validationPromptMustBeString: 'Prompt must be text',
    validationPromptTooLong: 'Prompt is too long',
    validationCharactersMustBeString: 'Characters must be text',
    validationCharactersTooLong: 'Characters is too long',
    validationDurationMustBeNumber: 'Duration minutes must be a whole number',
    validationDurationOutOfRange: 'Duration must be between 1 and 10 minutes',
    validationAgeMustBeNumber: 'Age must be a whole number',
    validationAgeOutOfRange: 'Age must be between 0 and 120',
    validationInspirationBookMustBeString: 'Inspiration book must be text',
    validationInspirationBookTooLong: 'Inspiration book is too long',
    contentBlockedHeading: 'Oops!',
    contentBlocked: "I tried to create a story for you, but my imagination went a little off track this time. Sometimes I come up with stories that might not be quite right for our awesome adventure together. I want to make sure all our stories are fun, safe, and perfect for you!\n\nWould you like to try again? I promise to do my very best to create something super special that we'll both love! Maybe you can help me by telling me what kind of story you'd like to hear - something about space? Animals? Magic? Together, we can make an amazing story happen!\n\nLet's give it another shot!",
  },
  he: {
    createNewBook: 'יצירת ספר חדש',
    plot: 'עלילה',
    enterPlot: 'הכניסו את העלילה עבור הסיפור ',
    expandForMore: 'הרחיבו לפרטים נוספים (אופציונלי)',
    characters: 'דמויות',
    enterCharacters: 'על מי הסיפור ',
    durationMinutes: 'משך בדקות',
    enterDuration: 'כמה זמן הסיפור צריך להיות?',
    childAge: 'גיל הילד',
    enterChildAge: 'מה הגיל (בשנים) של קהל היעד?',
    inspiredByBook: 'בהשראת הספר',
    enterInspiredBook: 'כותרת ספר/סרט שישמש כהשראה',
    storytellingModel: 'מודל ליצירת הסיפור',
    storyArtModel: 'מודל ליצירת תמונות',
    createStory: 'צור סיפור',
    library: 'ספרייה',
    newStory: 'סיפור חדש',
    poweredBy: 'מופעל על ידי',
    letsRead: 'בואו נקרא',
    removeStory: 'הסר סיפור',
    emptyLibrary: 'ספרייה ריקה',
    noStoriesSaved: 'נראה שאין לכם סיפורים שמורים.',
    createNewOne: 'זה בסדר - השתמשו בדמיון שלכם ליצור אחד חדש!',
    removeBookTitle: 'הסר ספר',
    removeBookConfirm: 'האם אתם בטוחים שברצונכם להסיר סיפור זה מהספרייה שלכם?',
    removeBookNote: 'אנשים שהסיפור שותף איתם עדיין יכולים לצפות בו.',
    cancel: 'ביטול',
    remove: 'הסר',
    youHave: ' יש לכם ',
    storiesInLibrary: ' סיפורים בספרייה שלכם. לחצו על אחד מהם לקבלת פרטים נוספים ולקריאה חוזרת. ',
    story: 'יש לכם סיפור אחד בספרייה שלך. לחצו עליו לקבלת פרטים נוספים ולקריאה חוזרת.',
    searchLibrary: 'חפש בספרייה',
    showing: 'מציג',
    createdByMe: ' נוצר על ידי לפני',
    readByMe: 'נקרא על ידי לפני ',
    createAnotherLike: 'צור סיפור נוסף כמו זה...',
    mustProvidePlot: 'חובה לספק עלילה',
    durationInvalid: 'משך הדקות אינו מספר תקין',
    ageInvalid: 'גיל הילד אינו מספר תקין',
    statusInitializing: 'זה רעיון נהדר, בואו נתחיל',
    statusCreating: 'אני חושבת...',
    statusTranscribing: 'אני מקליטה את קולי ומציירת כמה תמונות...',
    statusReady: 'הכל מוכן!',
    statusQueued: 'אני עסוקה בעזרה לאחרים כרגע, אהיה איתכם בעוד כמה רגעים. שבו בנוח והכינו שוקו חם!',
    pageNumber: 'עמוד',
    pageOf: 'מתוך',
    waitForSnack: 'זה יכול לקחת זמן, לכו להביא נשנוש ותחזרו בעוד כמה דקות',
    transcribedCount: 'הוקלטו {0} מתוך {1} קטעי קול',
    illustratedCount: 'צוירו {0} מתוך {1} תמונות',
    validationPromptCantBeBlank: 'העלילה לא יכולה להיות ריקה',
    validationPromptMustBeString: 'העלילה חייבת להיות מסוג טקסט',
    validationPromptTooLong: 'העלילה ארוכה מידי',
    validationCharactersMustBeString: 'רשימת הדמויות חייבת להיות מסוג טקסט',
    validationCharactersTooLong: 'רשימת הדמויות ארוכה מידי',
    validationDurationMustBeNumber: 'משך הדקות חייב להיות מספר',
    validationDurationOutOfRange: 'משך הדקות חייב להיות בין 1 ל-10',
    validationAgeMustBeNumber: 'גיל הילד חייב להיות מספר',
    validationAgeOutOfRange: 'גיל הילד חייב להיות בין 0 ל-120',
    validationInspirationBookMustBeString: 'הספר שישמש כהשראה חייב להיות מסוג טקסט',
    validationInspirationBookTooLong: 'הספר שישמש כהשראה ארוך מידי',
    contentBlockedHeading: 'אופס!',
    contentBlocked: 'ניסיתי ליצור סיפור בשבילך, אבל הדמיון שלי קצת סטה מהמסלול הפעם. לפעמים אני חושבת על סיפורים שאולי לא מתאימים בדיוק להרפתקה המשותפת שלנו. אני רוצה להבטיח שכל הסיפורים שלנו יהיו כיפיים, בטוחים ומושלמים בשבילך!\n\nרוצה לנסות שוב? אני מבטיחה לעשות את המיטב כדי ליצור משהו מיוחד במיוחד ששנינו נאהב! אולי תוכל/י לעזור לי ולספר לי איזה סוג של סיפור היית רוצה לשמוע - משהו על חלל? חיות? קסמים? ביחד, נוכל ליצור סיפור מדהים!\n\nבוא/י ננסה שוב!',
  },
  hu: {
    createNewBook: 'Új könyv létrehozása',
    plot: 'Cselekmény',
    enterPlot: 'Írd be a történeted cselekményét',
    expandForMore: 'További részletek testreszabása (opcionális)',
    characters: 'Szereplők',
    enterCharacters: 'Kikről szól a történeted (válaszd el "és" szóval)',
    durationMinutes: 'Időtartam percben',
    enterDuration: 'Milyen hosszú legyen a történeted?',
    childAge: 'Gyermek életkora',
    enterChildAge: 'Hány éves a célközönség?',
    inspiredByBook: 'Könyv által inspirálva',
    enterInspiredBook: 'Az inspirációként használt könyv címe',
    storytellingModel: 'Történetmesélési modell',
    storyArtModel: 'Történet illusztrációs modell',
    createStory: 'Történet létrehozása',
    library: 'Könyvtár',
    newStory: 'Új történet',
    poweredBy: 'Működteti:',
    letsRead: 'Olvassunk',
    removeStory: 'Történet törlése',
    emptyLibrary: 'Üres könyvtár',
    noStoriesSaved: 'Úgy tűnik, még nincsenek mentett történeteid.',
    createNewOne: 'Semmi gond - használd a képzeletedet és hozz létre egy újat!',
    removeBookTitle: 'Könyv törlése',
    removeBookConfirm: 'Biztosan törölni szeretnéd ezt a történetet a könyvtáradból?',
    removeBookNote: 'Akikkel megosztottad a történetet, továbbra is láthatják azt.',
    cancel: 'Mégse',
    remove: 'Törlés',
    youHave: 'Van ',
    storiesInLibrary: ' történeted a könyvtáradban. Kattints az egyikre a részletekért és újraolvasásért. ',
    story: 'Van egy történeted a könyvtáradban. Kattints rá a részletekért és újraolvasásért.',
    searchLibrary: 'Könyvtár keresése',
    showing: 'megjelenítve',
    createdByMe: 'Általam létrehozott',
    readByMe: 'Általam olvasott',
    createAnotherLike: 'Hozz létre egy hasonló történetet...',
    mustProvidePlot: 'A cselekmény megadása kötelező',
    durationInvalid: 'Az időtartam érvénytelen szám',
    ageInvalid: 'A gyermek életkora érvénytelen szám',
    statusInitializing: 'Nagyszerű ötlet, kezdjük el',
    statusCreating: 'Gondolkodom...',
    statusTranscribing: 'Rögzítem a hangom és rajzolok néhány képet...',
    statusReady: 'Minden kész!',
    statusQueued: 'Most épp másoknak segítek, egy pillanat és jövök. Addig is készítsd elő a forró csokoládét!',
    pageNumber: 'Oldal',
    pageOf: '/',
    waitForSnack: 'Ez eltarthat egy ideig, menj és hozz egy kis nassolnivalót, majd gyere vissza pár perc múlva',
    transcribedCount: '{1} felvételből {0} átírva',
    illustratedCount: '{1} képből {0} illusztrálva',
    validationPromptCantBeBlank: 'A prompt nem lehet üres',
    validationPromptMustBeString: 'A promptnak szövegnek kell lennie',
    validationPromptTooLong: 'A prompt túl hosszú',
    validationCharactersMustBeString: 'A karaktereknek szövegnek kell lenniük',
    validationCharactersTooLong: 'A karakterek túl hosszúak',
    validationDurationMustBeNumber: 'Az időtartamnak egész számnak kell lennie',
    validationDurationOutOfRange: 'Az időtartamnak 1 és 10 perc között kell lennie',
    validationAgeMustBeNumber: 'Az életkornak egész számnak kell lennie',
    validationAgeOutOfRange: 'Az életkornak 0 és 120 év között kell lennie',
    validationInspirationBookMustBeString: 'Az inspirációs könyvnek szövegnek kell lennie',
    validationInspirationBookTooLong: 'Az inspirációs könyv túl hosszú',
    contentBlockedHeading: 'Hoppá!',
    contentBlocked: 'Megpróbáltam egy történetet alkotni neked, de a képzeletem most egy kicsit félresiklott. Néha olyan történetek jutnak eszembe, amik talán nem illenek tökéletesen a közös kalandunkhoz. Szeretném biztosítani, hogy minden történetünk szórakoztató, biztonságos és tökéletes legyen számodra!\n\nSzeretnél újra próbálkozni? Ígérem, hogy mindent megteszek, hogy valami különlegeset alkossak, amit mindketten szeretni fogunk! Talán segíthetnél nekem azzal, hogy elmondod, milyen történetet szeretnél hallani - valamit az űrről? Állatokról? Varázslatról? Együtt egy csodálatos történetet alkothatunk!\n\nPróbáljuk meg újra!',
  },
};
