import React, { useMemo } from 'react';
import { DropdownButton, Dropdown } from 'react-bootstrap';
import { useLanguage } from '../i18n/LanguageContext';

// Language mapping object for better maintainability
const LANGUAGES = {
  en: 'English',
  he: 'עברית',
  hu: 'Magyar',
};

function LanguageToggle() {
  const { language, setLanguage } = useLanguage();
  const currentLangaugeText = useMemo(
    () => LANGUAGES[language as keyof typeof LANGUAGES],
    [language],
  );

  return (
    <DropdownButton
      id="language-dropdown"
      title={currentLangaugeText}
      variant="light"
      style={{ marginLeft: '10px' }}
    >
      {Object.entries(LANGUAGES).map(([code, title]) => (
        <Dropdown.Item
          key={code}
          onClick={() => setLanguage(code)}
          active={language === code}
        >
          {title}
        </Dropdown.Item>
      ))}
    </DropdownButton>
  );
}

export default LanguageToggle;
