import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Button,
  Col,
  Container,
  ProgressBar,
  Row,
  Stack,
} from 'react-bootstrap';
import { GrLinkNext, GrLinkPrevious } from 'react-icons/gr';
import { GiMagicLamp } from 'react-icons/gi';
import ReactPlayer from 'react-player';
import { getLanguage, useTranslation } from '../i18n';
import { APIClient, BookStatus } from '../lib/APIClient';
import {
  doesBookExist,
  getPendingBookIds,
  removePendingBookId,
  saveBook,
} from '../lib/Library';
import { AudioMetadata } from '../interfaces/AudioMetadata';
import { BookData } from '../interfaces/BookData';
import Header from './Header';
import Loading from './Loading';
import { SHARE_CHANNELS, renderButton, shareStory } from './Share';

const isHebrew = (str: string) => /[\u0590-\u05FF]/.test(str);

const apiClient = new APIClient();

const waitAsync = (ms: number) => new Promise((resolve) => { setTimeout(resolve, ms); });

const getStatusMessage = (status: string) => {
  const { t } = useTranslation();
  const messages = {
    initializing: t('statusInitializing'),
    creating: t('statusCreating'),
    transcribing: t('statusTranscribing'),
    ready: t('statusReady'),
    queued: t('statusQueued'),
    blocked: t('contentBlocked'),
  };
  return messages[status as keyof typeof messages] || status;
};

function ViewBook() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { bookId } = useParams();
  const [status, setStatus] = useState<BookStatus | null>(null);
  const [bookData, setBookData] = useState<BookData | null>(null);
  const [selectedPage, setSelectedPage] = useState(0);
  const [audioUrl, setAudioUrl] = useState<string | null>(null);
  const [isAudioAvailable, setIsAudioAvailable] = useState<boolean | null>(null);
  const [audioMetadata, setAudioMetadata] = useState<AudioMetadata | null>(null);
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [textIndexes, setTextIndexes] = useState<[number, number]>([0, 0]);

  if (!bookId) {
    throw new Error('Book ID is not found');
  }

  const loadPageData = useCallback(async (pageNumber: number) => {
    setAudioUrl(null);
    setAudioMetadata(null);
    setImageUrl(null);
    setTextIndexes([0, 0]);
    const promises = [
      async () => {
        setAudioUrl(await apiClient.getPageAudioUrl(bookId, pageNumber));
      },
      async () => {
        const fetchedAudioMetadata = await apiClient.getPageAudioMetadata(bookId, pageNumber);
        setAudioMetadata(fetchedAudioMetadata);
        setIsAudioAvailable(!!fetchedAudioMetadata);
      },
      async () => {
        setImageUrl(await apiClient.getPageImageUrl(bookId, pageNumber));
      },
    ];
    await Promise.all(promises.map((func) => func()));
  }, [bookId]);

  const changePage = useCallback(async (newSelectedPage: number) => {
    setSelectedPage(newSelectedPage);
    loadPageData(newSelectedPage);
  }, [loadPageData]);

  const loadBook = useCallback(async () => {
    console.log('Loading book data');
    const fetchedBookData = await apiClient.getBookData(bookId);
    if (fetchedBookData && !doesBookExist(bookId)) {
      const pendingBookIds = getPendingBookIds();
      const amIAuthor = !!pendingBookIds[bookId];
      console.log(`Saved book to library, amIAuthor = ${amIAuthor}`);
      saveBook(bookId, fetchedBookData.input.plot || '', fetchedBookData.title, fetchedBookData.pages.length, amIAuthor);
      if (amIAuthor) {
        removePendingBookId(bookId);
      }
    } else if (!fetchedBookData) {
      console.error('Book data not found');
      return;
    }
    setBookData(fetchedBookData);
    changePage(1);
  }, [bookId, changePage]);

  const pollStatus = useCallback(async () => {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      let lastBookStatus: BookStatus | undefined;
      try {
        // eslint-disable-next-line no-await-in-loop
        lastBookStatus = await apiClient.getBookStatus(bookId);
      } catch (err) {
        console.error('Error fetching status', err);
      }
      if (lastBookStatus) {
        console.log(`Status = ${lastBookStatus.status}`);
        setStatus(lastBookStatus);
        if (lastBookStatus.status === 'ready') {
          loadBook();
          return;
        }
      }
      // eslint-disable-next-line no-await-in-loop
      await waitAsync(3000);
    }
  }, [bookId, loadBook]);

  const handlePageCompleted = useCallback(() => {
    console.log('The page has been completed', { selectedPage });
    if (selectedPage >= bookData!.pages.length) {
      console.log('No more pages');
      return;
    }
    changePage(selectedPage + 1);
  }, [selectedPage, bookData, changePage]);

  const handleProgressAudio = useCallback((data: any) => {
    const timeIndexMs = data.playedSeconds * 1000;
    if (!audioMetadata) {
      return;
    }
    const thisTimeIndex = audioMetadata.times.findIndex(
      (timeData) => timeData.time > timeIndexMs - 1,
    );
    const thisTime = audioMetadata.times[thisTimeIndex];
    if (!thisTime) {
      return;
    }
    setTextIndexes([thisTime.start, thisTime.end]);
  }, [audioMetadata]);

  useEffect(() => {
    pollStatus();
  }, [pollStatus]);

  let body = <Loading />;
  if (!status || status.status !== 'ready') {
    let transcribedCount = 0;
    let drewCount = 0;
    let transcribedPercentage = 0;
    let drewPercentage = 0;
    let pageCount = 0;
    if (status?.pages) {
      pageCount = status.pages.length;
      transcribedCount = status.pages.filter((p) => p.transcribe === 'COMPLETED').length;
      drewCount = status.pages.filter((p) => p.draw === 'COMPLETED').length;
      transcribedPercentage = (transcribedCount * 100) / pageCount;
      drewPercentage = (drewCount * 100) / pageCount;
    }

    let progress;
    if (status && status.pages) {
      progress = (
        <Container style={{ direction: getLanguage() === 'he' ? 'rtl' : 'ltr' }}>
          <Row>
            <Col>
              {t('transcribedCount').replace('{0}', transcribedCount.toString()).replace('{1}', status.pages.length.toString())}
              {
                transcribedPercentage < 100
                  ? (
                    <ProgressBar
                      animated
                      now={transcribedPercentage}
                      variant="info"
                      className="mb-2"
                    />
                  )
                  : (
                    <ProgressBar
                      now={100}
                      variant="info"
                      className="mb-2"
                    />
                  )
              }
            </Col>
          </Row>
          <Row>
            <Col>
              {t('illustratedCount').replace('{0}', drewCount.toString()).replace('{1}', status.pages.length.toString())}
              {
                drewPercentage < 100
                  ? (
                    <ProgressBar
                      animated
                      now={drewPercentage}
                      variant="info"
                      className="mb-2"
                    />
                  )
                  : (
                    <ProgressBar
                      now={100}
                      variant="info"
                      className="mb-2"
                    />
                  )
              }
            </Col>
          </Row>
        </Container>
      );
    }

    if (status?.status === 'blocked') {
      body = (
        <div>
          <Alert variant="warning" className="m-4">
            <Alert.Heading>{t('contentBlockedHeading')}</Alert.Heading>
            <p>{getStatusMessage('blocked')}</p>
          </Alert>
        </div>
      );
    } else {
      body = (
        <>
          {status?.title ? <h1>{status?.title}</h1> : undefined}
          <Loading />
          <h3>
            {getStatusMessage(!status ? 'initializing' : status.status)}
          </h3>
          {progress}
          <h5>{t('waitForSnack')}</h5>
        </>
      );
    }
  } else if (bookData) {
    let start = 0;
    let end = 0;
    if (textIndexes.length === 2) {
      [start, end] = textIndexes;
    }
    const { text } = bookData.pages[selectedPage - 1];
    const preText = text.substring(0, start);
    const currentWord = text.substring(start, end);
    const postText = text.substring(end);

    body = (
      <>
        <Stack direction="horizontal" gap={3}>
          <div>
            <h1>
              {bookData.title}
            </h1>
          </div>
          {
            SHARE_CHANNELS.map((channel, index) => (
              <div className={index === 0 ? 'ms-auto' : ''} key={channel.name}>
                {
                  renderButton(
                    channel,
                    () => shareStory(channel, bookData!.bookId, bookData!.title),
                  )
                }
              </div>
            ))
          }
        </Stack>
        <>
          <h4 style={{ textAlign: getLanguage() === 'he' ? 'right' : 'left' }}>
            <Button
              variant="light"
              disabled={selectedPage === 1}
              onClick={() => changePage(selectedPage - 1)}
            >
              {getLanguage() === 'he' ? <GrLinkNext /> : <GrLinkPrevious />}
            </Button>
            {t('pageNumber')}
            {' '}
            {selectedPage}
            {' '}
            {t('pageOf')}
            {' '}
            {bookData.pages.length}
            <Button
              variant="light"
              disabled={selectedPage >= bookData.pages.length}
              onClick={() => changePage(selectedPage + 1)}
            >
              {getLanguage() === 'he' ? <GrLinkPrevious /> : <GrLinkNext />}
            </Button>
          </h4>
          <Container style={{ direction: isHebrew(text) ? 'rtl' : 'ltr' }}>
            <Row>
              <Col>
                { imageUrl ? <img src={imageUrl} alt="jenny generated" style={{ maxWidth: '90%' }} /> : <Loading />}
              </Col>
              <Col>
                <p>
                  {preText}
                  <span style={{ color: 'blue' }}>{currentWord}</span>
                  {postText}
                </p>
              </Col>
            </Row>
            { audioUrl && isAudioAvailable
              ? (
                <Row>
                  <Col className="m-2">
                    <ReactPlayer
                      height={30}
                      url={audioUrl}
                      width="100%"
                      playing
                      controls
                      progressInterval={100}
                      onProgress={handleProgressAudio}
                      onEnded={handlePageCompleted}
                    />
                  </Col>
                </Row>
              ) : undefined }
            <Row>
              <Col className="text-center">
                <Button
                  variant="info"
                  onClick={() => navigate(`/?fromBookId=${bookId}`)}
                  className="m-1"
                  style={{ direction: 'ltr' }}
                >
                  <GiMagicLamp />
                  {' '}
                  {t('createAnotherLike')}
                </Button>
              </Col>
            </Row>
          </Container>
        </>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="m-2" style={{ direction: getLanguage() === 'he' ? 'rtl' : 'ltr' }}>
        {body}
      </div>
    </>
  );
}

export default ViewBook;
