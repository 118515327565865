import configuration from '../configuration.json';
import { BookData } from '../interfaces/BookData';
import { AudioMetadata } from '../interfaces/AudioMetadata';

export interface BookStatus {
  status: 'initializing'|'creating'|'transcribing'|'ready'|'queued'|'blocked';
  title?: string;
  pages?: {
    draw: 'COMPLETED' | 'PENDING' | 'STARTING';
    transcribe: 'COMPLETED' | 'PENDING' | 'STARTING';
  }[]
}

export class APIClient {
  apiBaseUrl: string;

  resourcesBaseUrl: string;

  constructor() {
    this.apiBaseUrl = configuration.apiBaseUrl;
    this.resourcesBaseUrl = configuration.resourcesBaseUrl;
  }

  async createBook(
    plot: string,
    characters: string | null,
    durationMinutes: number| null,
    childYearsOld: number| null,
    inspirationBook: string| null,
    inspirationAuthor: string| null,
    storytellingModel: string| null,
    artModel: string| null,
  ): Promise<string> {
    const body = {
      plot,
      characters,
      durationMinutes,
      childYearsOld,
      inspirationBook,
      inspirationAuthor,
      storytellingModel,
      artModel,
    };
    const response = await this.makeRequest('/new', { method: 'POST', body });
    return response.bookId;
  }

  async getBookData(bookId: string): Promise<BookData | undefined> {
    const [response, statusCode] = await this.makeRequestWithStatusCode(`/data/${bookId}/-1/data`);
    if (statusCode === 404) {
      return undefined;
    } if (statusCode === 200) {
      return response;
    }
    throw new Error(`Invalid response from server on getBookData :: ${statusCode}`);
  }

  getBookPageResourcesUrl(bookId: string, page: number): string {
    return `${this.resourcesBaseUrl}/${bookId}/page-${page.toString().padStart(3, '0')}`;
  }

  getPageAudioUrl(bookId: string, page: number): string {
    return `${this.getBookPageResourcesUrl(bookId, page)}/recording.mp3`;
  }

  getPageImageUrl(bookId: string, page: number): string {
    return `${this.getBookPageResourcesUrl(bookId, page)}/image.jpeg`;
  }

  async getPageAudioMetadata(bookId: string, page: number): Promise<AudioMetadata | null> {
    const [response, statusCode] = await this.makeRequestWithStatusCode(`${this.getBookPageResourcesUrl(bookId, page)}/audio-meta.jsonl`, { ignoreBaseUrl: true, format: 'jsonl' });
    if (statusCode === 403 || statusCode === 404) {
      return null;
    } if (statusCode !== 200) {
      throw new Error(`Invalid response from server for audio metadata (${statusCode})`);
    }
    return { times: response };
  }

  async getBookStatus(bookId: string): Promise<BookStatus> {
    const response = await this.makeRequest(`/data/${bookId}/-1/status`);
    return response;
  }

  async makeRequest(
    path: string,
    data: { method?: string, query?: {[key: string]: string }, body?: any } = {},
  ): Promise<any> {
    const [response, statusCode] = await this.makeRequestWithStatusCode(path, data);
    if (statusCode !== 200) {
      throw new Error(`Invalid response from server (${statusCode})`);
    }
    return response;
  }

  async makeRequestWithStatusCode(
    path: string,
    data: {
      method?: string,
      query?: {[key: string]: string },
      body?: any,
      ignoreBaseUrl?: boolean,
      format?: 'json' | 'text' | 'jsonl',
      noCors?: boolean,
    } = {},
  ): Promise<[any, number]> {
    let url = `${data.ignoreBaseUrl ? '' : this.apiBaseUrl}${path}`;
    const requestConfig: any = {
      method: data.method || 'GET',
      headers: {},
    };

    if (data.noCors) {
      requestConfig.mode = 'no-cors';
    }

    if (data.body) {
      requestConfig.headers['content-type'] = 'application/json';
      requestConfig.body = JSON.stringify(data.body);
    }

    if (data) {
      if (data.method) {
        requestConfig.method = data.method;
      }
      if (data.query) {
        const queryString = Object.keys(data.query)
          .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data.query![key] || '')}`)
          .join('&');
        url += `?${queryString}`;
      }
    }

    const response = await fetch(url, requestConfig);
    const rawBody = await response.text();
    let body: any = rawBody;

    if (!data.format || data.format === 'json') {
      try {
        body = JSON.parse(rawBody);
        // eslint-disable-next-line no-empty
      } catch {}
    } else if (data.format === 'jsonl') {
      const lines = rawBody.split('\n');
      try {
        body = lines.filter((line) => line).map((line) => JSON.parse(line));
        // eslint-disable-next-line no-empty
      } catch {}
    }

    return [body, response.status];
  }
}
